<!-- 表二明细表 -->
<template>
    <div class="detailedTwo">
        <!-- 上面部分 -->
        <div class="top">
            <span style="font-size: 16px; font-weight: bold;">明细表</span>
            <el-button class="el-btn" size="small" @click="goBack">
                返回
            </el-button>
        </div>
        <button class="derive" @click="derive">导出到Excel</button>
        <!-- 表格 -->
        <el-table :data="tableData" border stripe style="width:97.9%;flex: 1;" height="650" @sort-change="abc">
            <el-table-column type="index" label="序号" align="center" width="80">
            </el-table-column>
            <el-table-column prop="certificate_no" label="准考证号" sortable="custom" align="center" width="200">
            </el-table-column>
            <el-table-column prop="username" label="学员姓名" sortable="custom" align="center" width="150">
            </el-table-column>
            <el-table-column prop="pinyin_name" label="拼音名" sortable="custom" align="center" width="150">
            </el-table-column>
            <el-table-column prop="gender" label="性别" sortable="custom" align="center" width="80">
            </el-table-column>
            <el-table-column prop="nation2" label="国籍" sortable="custom" align="center" width="80">
            </el-table-column>
            <el-table-column prop="nation" label="民族" sortable="custom" align="center" width="80">
            </el-table-column>
            <el-table-column prop="birthdate" label="出生日期" sortable="custom" align="center" width="120">
            </el-table-column>
            <el-table-column prop="course_name" label="学科" sortable="custom" align="center" width="100">
            </el-table-column>
            <el-table-column prop="exam_level" label="等级码" sortable="custom" align="center" width="100">
            </el-table-column>
            <el-table-column prop="original_level" label="等级" sortable="custom" align="center" width="80">
            </el-table-column>
            <el-table-column prop="apply_category" label="考试大类" sortable="custom" align="center" width="200">
            </el-table-column>
            <el-table-column prop="enroll_price" label="考试费" sortable="custom" align="center" width="100">
            </el-table-column>
            <el-table-column prop="remark" label="说明" sortable="custom" align="center" width="200">
            </el-table-column>
            <el-table-column prop="enroll_number" label="安排单号" sortable="custom" align="center" width="150">
            </el-table-column>
            <el-table-column prop="org_name" label="提供机构" sortable="custom" align="center" width="150">
            </el-table-column>
            <!-- <el-table-column prop="" label="助记码" sortable="custom" align="center" width="120">
            </el-table-column> -->
            <el-table-column prop="is_deputy" label="有做副证" sortable="custom" align="center" width="120">
            </el-table-column>
        </el-table>
        <!-- 分页 -->
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage"
            :page-sizes="[20,100,500,2000,5000,50000]" :page-size="10" background
            layout="total, sizes, prev, pager, next, jumper" :total="total">
        </el-pagination>
    </div>
</template>

<script>
    export default{
        data() {
            return{
                tableData:[],
                currentPage: 1,
                currentLimit: 20,
                total: 0,
                arr:[]
            }
        },
        created() {
            this.getList()
        },
        methods:{
            goBack() { //返回
                this.$router.go(-1)
            }, 
            getList(){
                this.$request({
                    url:"/api/Statistics/student_stics",
                    method:"POST",
                    data:{
                        page:this.currentPage,
                        limit:this.currentLimit,
                        course_id:this.$route.query.course_id,
                        level:this.$route.query.exam_level,
                        org_id:this.$route.query.org_id,
                        agroup:this.$route.query.agroup
                    }
                }).then(res=>{
                    if(res.code==1){
                        console.log(res,"列表")
                        this.tableData=res.data.list
                        this.total=res.data.total
                    }
                })
            },
            handleSizeChange(val) {
                this.currentLimit=val
                this.getList()
                // console.log(`每页 ${val} 条`);
            },
            handleCurrentChange(val) {
                this.currentPage=val
                this.getList()
                // console.log(`当前页: ${val}`);
            },
            //排序
            abc(i){ 
                this.tableData=[]
                this.arr=[]
                this.arr.push({
                    title:i.prop,
                    order:i.order
                })
                console.log(this.arr)
                this.getList()
            },
            // 导出
            derive(){
                location.href =
                    `https://artxt.szart.cn/api/public/index.php/api/statistics/export_student_stics?
                    page=${this.currentPage}&limit=${this.currentLimit}&exam_date=${this.$route.query.exam_date}&org_id=${this.$route.query.org_id}&course_id=${this.$route.query.course_id}&type=${this.$route.query.type}&apply_category=${this.$route.query.apply_category}&level=${this.$route.query.exam_level}&agroup=${this.$route.query.agroup}`
            }
        }
    }
</script>

<style scoped="scoped">
    .detailedTwo{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
    }
    /* 上面部分 */
    .top {
        padding-top: 25px;
        margin: 0px 24px 20px 28px;
        display: flex;
        justify-content: space-between;
    }
    
    .el-btn {
        background: #18BC9C;
        padding-left: 25px;
        background-position: 5px 7px;
        display: inline-block;
        background-image: url(../../assets/back.png);
        background-repeat: no-repeat;
        color: #FFFFFF;
    }
    /* 表格 */
    .el-table {
        margin: 20px;
    }
    
     ::v-deep .el-table__cell {
        color: #000;
        padding: 0;
        height: 29px;
    }
    /* 导出按钮 */
    .derive {
        display: inline-block;
        width: 113px;
        height: 30px;
        padding-left: 10px;
        vertical-align: middle;
        background: url(../../assets/export.png) 6px 6px no-repeat #FF7300;
        color: #FFFFFF;
        border-radius: 3px;
        border: none;
        margin-left: 20px;
        cursor: pointer;
        outline: none;
    }
    /* 分页 */
    .el-pagination {
        margin: 20px;
        margin-top: 0px;
    }
    ::v-deep.el-pagination.is-background .el-pager li:not(.disabled).active{
        background-color: #2C3E50 !important;
    }
</style>
